import React, { Fragment } from 'react';
import Header from '@components/Header';
import Footer from '@components/Footer';
import { graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { snakeCase } from 'lodash';
import { css } from '@emotion/react';
import { createUtmQuery } from '@lib/searchQuery';
import FrontpageLink from '@components/FrontpageLink';
import BackgroundImage from 'gatsby-background-image';
import PageTitle from '@components/ui/PageTitle';
import CtaButton from '@components/ui/CtaButton';
import TestimonialBlock from '@components/ui/TestimonialBlock';
import Hero from '@components/theme/Hero';
import { getFirstBlock } from '@lib/blocks';

const utmMedium = 'testemonials';

export default function TestimonialsTemplate({ data: { page }, pageContext: { language, refs, layouts, configuration } }) {
  const { link, title, testimonials } = getFirstBlock(page);
  return (
    <Fragment>
      <Hero title={title} divider={{ variant: 1, reflect: true }}>
        <Header
          language={language}
          refs={refs}
          layouts={layouts}
        />
      </Hero>
      <div className='container mb-5 pb-5 mt-5'>
        {testimonials.map((testimonial, index) =>
          <TestimonialBlock key={index} testimonial={testimonial} />
        )}
        <CtaButton link={link} utmMedium={utmMedium} utmContent="cta_button_testimonials" />
      </div>
      <Footer
        configuration={configuration}
        language={language}
        layouts={layouts}
      />
    </Fragment>
  );
}

export const query = graphql`
  query($testimonialsId: String) {
    page: wpCustomPage(id: { eq: $testimonialsId }) {
      id
      language {
        code
      }
      block {
        block {
          ... on WpCustomPage_Block_Block_TestimonialsSection {
            title
            link {
              linkUrl
              linkText
            }
            testimonials {
              name
              authorName
              authorProfession
              text
              image {
                localFile {
                  sharp: childImageSharp {
                    fixed(width: 160, height: 160) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              authorImage {
                localFile {
                  sharp: childImageSharp {
                    fixed(width: 160, height: 160) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;