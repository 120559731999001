import React from 'react';
import { css } from '@emotion/react';
import FrontpageLink from '@components/FrontpageLink';
import { buildUtmUrl } from '@lib/searchQuery';

export default function CtaButton({ link, dark, ...props }) {
  const color = dark ? 'var(--primary)' : '#ffffff';
  const backgroundColor = dark ? '#ffffff' : 'var(--primary)';
  return (
    <div css={css`
              display: flex;
              flex-grow: 1;
              justify-content: center;
              padding-top: 3rem;
            `}
    >
      <FrontpageLink
        {...props}
        url={link.linkUrl}
        text={link.linkText}
        className={'btn btn-primary btn-lg shadow-sm'}
        css={css`
              color: ${color};
              background-color: ${backgroundColor};
              padding: 0.2em 1em;
              border-radius: 1.25rem;
              text-transform: uppercase;
              font-weight: 600;
              font-size: 1.5rem;
            `}
      />
    </div>
  );
}